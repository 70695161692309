/** @jsx jsx */
import { Chip, Grow } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { jsx } from "theme-ui";
import * as styles from '../pages/Index.module.scss';
import CardIconButton from './CardIconButton';

export enum ICONS {
  EXTERNAL_LINK,
  GITHUB,
  LINKEDIN,
  GOOGLE_PLAY,
  APPLE_APP_STORE,
  NPM,
  SAMSUNG,
  AMAZON,
}

export interface CardContent {
  body?: string
  title?: string
  image?: Image
  links?: Links
}

interface ChipContent {
  icon?: JSX.Element
  label?: string
}

export type Chips = ChipContent[]

export interface LinkContent {
  icon?: ICONS
  href?: string
}

type Links = LinkContent[]

interface Image {
  source?: string
  title?: string
}

export interface DialogContent {
  body?: string
  title?: string
  images?: Image[]
  links?: Links
}
interface ProjectCardProps {
  key?: number
  cardStyle: React.CSSProperties
  card: CardContent
  dialog?: DialogContent
  chips?: Chips
}
interface ProjectCardState {
  isModalOpen: boolean
}

export default class ProjectCard extends React.Component<ProjectCardProps, ProjectCardState> {
  state = {
    isModalOpen: false
  }

  openModal = () => this.setState({ isModalOpen: true })
  closeModal = () => this.setState({ isModalOpen: false })

  Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
  })

  renderChips = (): JSX.Element[] | null => {
    const { chips } = this.props
    if (!chips || chips instanceof Array && !chips.length) {
      return null
    }
    const Chips = chips.map((chip, index) => {
      let { icon, label } = chip
      return (<Chip
        key={index}
        icon={icon}
        label={label}
        color={"primary"}
        variant={"outlined"}
        clickable={true}
        className={styles.Chip}
        style={{padding: 4}}
      />)
    })
    return Chips
  }

  renderCardMedia = (): JSX.Element | null => {
    const { card } = this.props
    let image: Image | undefined
    if (card && card.image) image = card.image
    if (!image || !image.source) {
      return null
    } else {
      return (<CardMedia
        style={{ height: 100 }}
        image={image.source}
        title={image.title}
        alt={image.title}
        component="img"
      />)
    }
  }

  renderButtons = (): JSX.Element | null => {
    const { card } = this.props
    let links: Links = []
    if (card && card.links) links = card.links
    if (!links.length) {
      return null
    } else {
      const buttons = links.map((link, index) => CardIconButton(link, index))
      return (
        <CardActions >
          {buttons}
        </CardActions>
      )
    }
  }

  isDialogDisabled = () => {
    const { dialog } = this.props
    let dialogDisabled = true
    if (dialog && dialog instanceof Object) {
      dialogDisabled = !Object.keys(dialog).length
    }
    return dialogDisabled
  }

  render() {
    const { cardStyle, card } = this.props
    const { isModalOpen } = this.state
    const dialogDisabled = this.isDialogDisabled()
    return (
      //@ts-ignore
      <div
        sx={{
          width: `100%`,
          position: `relative`,
          textDecoration: `none`,
          borderRadius: `lg`,
          transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
          "&:hover": {
            transform: `translateY(-5px)`,
            boxShadow: `xl`,
          },
        }}
      >
        <Card style={cardStyle}>
          <CardActionArea
            onClick={this.openModal}
            disabled={dialogDisabled}
          >
            {this.renderCardMedia()}
            <CardContent>
              <Typography gutterBottom variant="h4" component="h4" className={styles.CardHeader}>
                {card.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ color: "white" }} component="h6" className={styles.CardBody}>
                {card.body}
              </Typography>
              {this.renderChips()}
            </CardContent>
          </CardActionArea>
          {this.renderButtons()}
        </Card>
        <Dialog onClose={this.closeModal} aria-labelledby="customized-dialog-title" open={isModalOpen}
          TransitionComponent={this.Transition}>
          <DialogTitle disableTypography>
            <Typography variant="h5">Modal title</Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
              in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </Typography>
            <Typography gutterBottom>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
              lacus vel augue laoreet rutrum faucibus dolor auctor.
          </Typography>
            <Typography gutterBottom>
              Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
              scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus
              auctor fringilla.
          </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeModal} color="primary">
              Save changes
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
