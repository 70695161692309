
/** @jsx jsx */
import { jsx } from "theme-ui";
import ProjectCard, { CardContent, Chips, DialogContent } from '../../../components/ProjectCard';
import { PROJECT_CARDS } from '../../../sections/project-content';
import ProjectsMDX from "../../../sections/projects.mdx";
import Content from "../elements/content";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import { UpDown, UpDownWide } from "../styles/animations";
import SVG from "./svg";

export interface Project {
  background: string
  card: CardContent
  dialog?: DialogContent
  chips?: Chips
}

const Projects = ({ offset }: { offset: number }) => {
  const cards = PROJECT_CARDS.map((project, index) => {
    let cardStyle: React.CSSProperties = { background: project.background, height: "100%" }
    return (<ProjectCard
      key={index}
      cardStyle={cardStyle}
      card={project.card}
      chips={project.chips}
      dialog={project.dialog}
    />)
  })
  return (
    <div>
      <Divider
        bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
        sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
        speed={-0.2}
        offset={1.1}
        factor={2}
      />
      <Content speed={0.4} offset={offset + 0.2} factor={2}>
        <Inner>
          <div
            sx={{
              display: `grid`,
              gridGap: [4, 4, 4, 5],
              gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
              h2: { gridColumn: `-1/1`, color: `white !important` },
            }}
          >
            <ProjectsMDX />
            {cards}
          </div>
        </Inner>
      </Content>
      <Divider speed={0.1} offset={offset} factor={2}>
        <UpDown>
          <SVG icon="box" width={6} color="icon_brightest" left="85%" top="75%" />
          <SVG icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
          <SVG icon="circle" width={16} color="icon_yellow" left="70%" top="90%" />
          <SVG icon="triangle" hiddenMobile width={16} stroke color="icon_teal" left="18%" top="75%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="hexa" width={8} stroke color="icon_yellow" left="80%" top="70%" />
          <SVG icon="arrowUp" hiddenMobile width={16} color="icon_green" left="20%" top="90%" />
          <SVG icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="30%" />
          <SVG icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
          <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
          <SVG icon="upDown" hiddenMobile width={8} color="icon_green" left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="circle" hiddenMobile width={6} color="icon_darker" left="4%" top="20%" />
        <SVG icon="hexa" width={16} stroke color="icon_red" left="75%" top="30%" />
        <SVG icon="circle" width={6} color="icon_brightest" left="75%" top="10%" />
        <SVG icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
        <SVG icon="triangle" width={8} stroke color="icon_pink" left="25%" top="5%" />
        <SVG icon="circle" hiddenMobile width={24} color="icon_brightest" left="17%" top="60%" />
      </Divider>
    </div>
  )
}

export default Projects
