import * as React from 'react';
import Cara from '../themes/cara/templates/cara';

interface IndexPageProps {
}

export default class IndexPage extends React.Component<IndexPageProps, {}> {

  public render() {
    return (
      <Cara />
    )
  }
}