import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';
import { FaAmazon, FaAppStore, FaExternalLinkAlt, FaGithub, FaGooglePlay, FaLinkedin, FaNpm } from 'react-icons/fa';
import { SiSamsung } from 'react-icons/si';
import * as styles from '../pages/Index.module.scss';
import { ICONS, LinkContent } from './ProjectCard';

export default function CardIconButton(link: LinkContent, key: number): JSX.Element | null {
  const { icon, href } = link
  let iconToRender: JSX.Element | undefined
  switch (icon) {
    case ICONS.EXTERNAL_LINK:
      iconToRender = <FaExternalLinkAlt />
      break;
    case ICONS.GITHUB:
      iconToRender = <FaGithub />
      break;
    case ICONS.LINKEDIN:
      iconToRender = <FaLinkedin />
      break;
    case ICONS.GOOGLE_PLAY:
      iconToRender = <FaGooglePlay />
      break;
    case ICONS.APPLE_APP_STORE:
      iconToRender = <FaAppStore />
      break;
    case ICONS.NPM:
      iconToRender = <FaNpm />
      break;
    case ICONS.SAMSUNG:
      iconToRender = <SiSamsung />
      break;
    case ICONS.AMAZON:
      iconToRender = <FaAmazon />
      break;
  }
  if (!iconToRender) return null
  return (
    <IconButton
      key={key}
      color="primary"
      target="_blank"
      href={href as string}
      className={styles.Button}
    >
      {iconToRender}
    </IconButton>
  )
}
