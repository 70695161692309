import * as React from 'react';
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { Parallax } from "react-spring/renderprops-addons.cjs";
import { ThemeProvider } from 'theme-ui';
import About from "../components/about";
import Contact from "../components/contact";
import Intro from "../components/intro";
import Layout from "../components/layout";
import Projects from "../components/projects";
import theme from '../styles/theme';

type CaraProps = {
};

const DEFAULT_VALUES = {
  parralax: 5,
  intro: 0,
  projects: 1,
  about: 3,
  contact: 4,
};
const MOBILE_VALUES = {
  parralax: 8,
  intro: 0,
  projects: 2.5,
  about: 6,
  contact: 7,
};

const NARROW_THRESHOLD = 650;

export const Cara = (props: CaraProps) => {
  const [width, setWidth] = React.useState(0);

  const handleResize = () => {
    setWidth(window && window.innerWidth || 0);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isNarrow = width < NARROW_THRESHOLD;

  let valuesToUse = DEFAULT_VALUES;
  if (isNarrow) {
    valuesToUse = MOBILE_VALUES;
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Parallax pages={valuesToUse.parralax}>
          <Intro offset={valuesToUse.intro} />
          <Projects offset={valuesToUse.projects} />
          <About offset={valuesToUse.about} />
          <Contact offset={valuesToUse.contact} />
        </Parallax>
      </Layout>
    </ThemeProvider>
  );
};

export default Cara;
