import { ICONS } from '../components/ProjectCard';
import { Project } from '../themes/cara/components/projects';

export const PROJECT_CARDS: Project[] = [
  {
    background: "#ed64a6",
    card: {
      title: "Korean Learners' Dictionary",
      body: "This app accesses, parses, and displays the dictionary database of the National Institute of Korean Language (국립국어원). Built and designed alone from the ground up. Over 50,000+ downloads.",
      image: {
      },
      links: [
        {
          icon: ICONS.GOOGLE_PLAY,
          href: "https://web.archive.org/web/20230822142416/https://play.google.com/store/apps/details?id=com.schurawel.kl_dictionary",
        },
        {
          icon: ICONS.APPLE_APP_STORE,
          href: "https://web.archive.org/web/20220818160632/https://apps.apple.com/app/korean-learners-dictionary/id1417101471",
        },
        {
          icon: ICONS.AMAZON,
          href: "https://web.archive.org/web/20230823182700/https://www.amazon.com/gp/product/B098DNPQ8Y",
        },
        {
          icon: ICONS.SAMSUNG,
          href: "https://web.archive.org/web/20230823182631/https://galaxystore.samsung.com/detail/com.schurawel.kl_dictionary",
        },
      ]
    },
    chips: [
      {
        label: "react-native",
      },
      {
        label: "javascript",
      },
      {
        label: "mobx",
      },
      {
        label: "iOS",
      },
      {
        label: "android",
      },
    ],
  },
  {
    background: "#9e009a",
    card: {
      title: "Outrun - Animated Retro Watch Face",
      body: "An animated watchface for Android Wear and the Samsung Gear/Galaxy wearable devices",
      image: {
      },
      links: [
        {
          icon: ICONS.GOOGLE_PLAY,
          href: "https://play.google.com/store/apps/details?id=com.schurawel.outrun",
        },
        {
          icon: ICONS.SAMSUNG,
          href: "https://galaxy.store/outrun",
        },
      ]
    },
    chips: [
      {
        label: "watch",
      },
    ],
  },
  // {
  //   background: "#1da1a7",
  //   card: {
  //     title: "Landscape - Time of Day",
  //     body: "This app accesses, parses, and displays the dictionary database of the National Institute of Korean Language (국립국어원). Built and designed alone from the ground up. Over 50,000+ downloads.",
  //     image: {
  //     },
  //     links: [
  //       {
  //         icon: ICONS.SAMSUNG,
  //         href: "https://galaxy.store/timeland",
  //       },
  //     ]
  //   },
  //   chips: [
  //     {
  //       label: "watch",
  //     },
  //   ],
  // },
  {
    background: "#ed8936",
    card: {
      title: "React Native AnkiDroid",
      body: "An open source React Native wrapper for the AnkiDroid API",
      links: [
        {
          icon: ICONS.NPM,
          href: "https://www.npmjs.com/package/react-native-ankidroid",
        },
        {
          icon: ICONS.GITHUB,
          href: "https://github.com/is343/react-native-ankidroid",
        },
      ]
    },
    chips: [
      {
        label: "open source",
      },
      {
        label: "react-native",
      },
      {
        label: "typescript",
      },
      {
        label: "java",
      },
      {
        label: "android",
      },
    ],
    dialog: {},
  },
  {
    background: "#e53e3e",
    card: {
      title: "Delay Overdue Cards",
      body: "An Anki 2.1 add-on that delays all cards within a deck by the number of days the deck is overdue.",
      image: {
        source: "",
      },
      links: [
        {
          icon: ICONS.GITHUB,
          href: "https://github.com/is343/anki_delay_deck",
        },
        {
          icon: ICONS.EXTERNAL_LINK,
          href: "https://ankiweb.net/shared/info/43919973",
        },
      ]
    },
    chips: [
      {
        label: "open source",
      },
      {
        label: "python",
      },
      {
        label: "anki",
      },
    ],
    dialog: {},
  },
//   {
//     background: "#76b1e2",
//     card: {
//       title: "Zeroth Internet Explorer Compatablity Library and Plugin",
//       body: "An updated version of the library for the Zeroth voice service. It allows connection to a custom ActiveX plugin to access microphone data and communicate with the Zeroth servers.", // for posco ict
//       image: {
//       },
//     },
//     chips: [
//       {
//         label: "javascript",
//       },
//       {
//         label: "C++",
//       },
//       {
//         label: "websockets",
//       },
//       {
//         label: "IE",
//       },
//       {
//         label: "Windows",
//       },
//     ],
//   },
  {
    background: "#555083",
    card: {
      title: "Morecoin App",
      body: "An app for supplying voice data for training language-based AI systems. Updated the app logic to work with the newly updated backend server, and to allow the use of more training models.",
      image: {
      },
      links: [
        {
          icon: ICONS.GOOGLE_PLAY,
          href: "https://play.google.com/store/apps/details?id=com.goodatlas.morecoin",
        },
        {
          icon: ICONS.APPLE_APP_STORE,
          href: "https://itunes.apple.com/app/morecoin/id1351621392",
        },
      ]
    },
    chips: [
      {
        label: "react-native",
      },
      {
        label: "typescript",
      },
      {
        label: "redux",
      },
      {
        label: "iOS",
      },
      {
        label: "android",
      },
    ],
  },
//   {
//     background: "#0070c9",
//     card: {
//       title: "Morecoin Server",
//       body: "The backend server for the Morecoin service. Added direct database manipulation to simplify the process and stop the need to use a tertiary server.",
//       image: {
//       },
//       links: []
//     },
//     chips: [
//       {
//         label: "node",
//       },
//       {
//         label: "typescript",
//       },
//       {
//         label: "javascript",
//       },
//       {
//         label: "python",
//       },
//       {
//         label: "keycloak",
//       },
//       {
//         label: "postgresql",
//       },
//     ],
//   },
  {
    background: "#48bb78",
    card: {
      title: "PayCheck Global",
      body: "Built the global version of the app completely new from the ground up, from design to implementation.",
      image: {
      },
      links: [
        {
          icon: ICONS.GOOGLE_PLAY,
          href: "https://play.google.com/store/apps/details?id=com.mpaycheck.paycheck_global",
        },
        {
          icon: ICONS.APPLE_APP_STORE,
          href: "https://apps.apple.com/app/paycheck-global/id1465675995",
        },
      ]
    },
    chips: [
      {
        label: "react-native",
      },
      {
        label: "typescript",
      },
      {
        label: "mobx",
      },
      {
        label: "iOS",
      },
      {
        label: "android",
      },
    ],
    dialog: {},
  },
  {
    background: "#ecc94b",
    card: {
      title: "Daejeon Metropolitan Council Employee Management",
      body: "An app to manage goverment employee schedules and work logs.",
      image: {
      },
      links: [
        {
          icon: ICONS.GOOGLE_PLAY,
          href: "https://play.google.com/store/apps/details?id=com.mpaycheck.daejeon_seo_gu_council",
        },
        // {
        //   icon: ICONS.EXTERNAL_LINK,
        //   href: "https://council.daejeon.go.kr/jsp/english/index.jsp",
        // },
      ]
    },
    chips: [
      {
        label: "react-native",
      },
      {
        label: "typescript",
      },
      {
        label: "mobx",
      },
      {
        label: "iOS",
      },
      {
        label: "android",
      },
    ],
    dialog: {},
  },
  {
    background: "#805ad5",
    card: {
      title: "Made With React Native - Development Interview",
      body: "Interviewed about my development process while making the Korean Learners Dictionary.",
      image: {
        source: "",
      },
      links: [
        {
          icon: ICONS.EXTERNAL_LINK,
          href: "https://madewithreactnative.com/koreanlearnersdictionary/",
        },
      ]
    },
    chips: [
      {
        label: "react-native",
      },
      {
        label: "interview",
      },
    ],
    dialog: {},
  },
];
