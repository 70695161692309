/** @jsx jsx */
import { Flex, Footer as ThemeFooter, jsx, Styled } from "theme-ui";

const Footer = () => {

  return (
    <ThemeFooter>
      Copyright &copy; {new Date().getFullYear()}. All rights reserved.
    </ThemeFooter>
  )
}

export default Footer
