/** @jsx jsx */
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { jsx, Styled } from "theme-ui";
import * as styles from '../../../pages/Index.module.scss';
import IntroMDX from "../../../sections/intro.mdx";
import Content from "../elements/content";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import { UpDown, UpDownWide } from "../styles/animations";
import SVG from "./svg";

const Intro = ({ offset }: { offset: number }) => (
  <div>
    <Divider speed={0.2} offset={offset}>
      <UpDown>
        <SVG icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
        <SVG icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
        <SVG icon="box" width={6} color="icon_purple" left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="arrowUp" hiddenMobile width={16} color="icon_darker" left="80%" top="10%" />
        <SVG icon="triangle" width={12} stroke color="icon_orange" left="90%" top="50%" />
        <SVG icon="circle" width={16} color="icon_darker" left="70%" top="90%" />
        <SVG icon="triangle" hiddenMobile width={48} stroke color="icon_darkest" left="10%" top="20%" />
        <SVG icon="hexa" width={48} stroke color="icon_red" left="60%" top="70%" />
        <SVG icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
        <SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
      </UpDownWide>
      <SVG icon="circle" hiddenMobile width={24} color="icon_darker" left="5%" top="70%" />
      <SVG icon="upDown" hiddenMobile width={24} color="icon_darker" left="40%" top="80%" />
      <SVG icon="triangle" width={8} stroke color="icon_darkest" left="25%" top="5%" />
      <SVG icon="circle" width={64} color="icon_green" left="95%" top="5%" />
      <SVG icon="triangle" width={16} stroke color="icon_darker" left="30%" top="65%" />
      <SVG icon="cross" width={16} stroke color="icon_pink" left="28%" top="15%" />
      <SVG icon="circle" width={6} color="icon_darker" left="75%" top="10%" />
      <SVG icon="box" hiddenMobile width={64} color="icon_purple" left="5%" top="90%" />
      <SVG icon="box" width={6} color="icon_blue" left="10%" top="10%" />
      <SVG icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
      <SVG icon="hexa" width={16} stroke color="icon_yellow" left="10%" top="50%" />
      <SVG icon="hexa" width={8} stroke color="icon_brightest" left="80%" top="70%" />
    </Divider>
    <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={offset}>
      <Inner className={styles.Intro}>
        <IntroMDX />
        <h3>
          <Styled.a
            sx={{ color: `text`, m: 3 }}
            target="_blank"
            href="https://www.linkedin.com/in/ivan-schurawel"
            rel="noreferrer noopener"
          >
            <FaLinkedin size={"3em"} />
          </Styled.a>
          <Styled.a
            sx={{ color: `text`, m: 3 }}
            target="_blank"
            href="https://www.github.com/is343"
            rel="noreferrer noopener"
          >
            <FaGithub size={"3em"} />
          </Styled.a>
        </h3>
      </Inner>
    </Content>
  </div>
)

export default Intro
